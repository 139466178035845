<div *ngIf="advertisers"
     class="marquee-container">

  <ng-container *ngIf="advertisers.length * 10 as animationSeconds">

    <!-- plot list 4 times to make do the marquee magic -->
    <ng-container *ngTemplateOutlet="listOfAdvertisers"></ng-container>
    <ng-container *ngTemplateOutlet="listOfAdvertisers"></ng-container>
    <ng-container *ngTemplateOutlet="listOfAdvertisers"></ng-container>
    <ng-container *ngTemplateOutlet="listOfAdvertisers"></ng-container>

    <ng-template #listOfAdvertisers>
      <div class="list"
           [style.animation-duration]="animationSeconds + 's'">
        <ng-container *ngFor="let advertiser of advertisers; let index = index">

          <div *ngIf="advertiser.isCustomLink; else showNormalAdvertiser"
               class="item">
            <div class="custom-link">
              {{ 'generic.Ook adverteren?' | translate }}

              <a mat-raised-button
                 routerLink="/{{APP_ROUTES.supportUs}}/{{APP_ROUTES.advertisers}}"
                 color="accent">
                <mat-icon>featured_video</mat-icon>
                {{ 'generic.Plaats advertentie' | translate }}
              </a>
            </div>

          </div>

          <ng-template #showNormalAdvertiser>
            <div class="item"
                 (click)="onAdvertiserClick(advertiser)">
              <img *ngIf="(advertiser.logoPath || advertiser.logoBase64) as logo; else showText"
                   src="{{logo}}"
                   alt="{{advertiser.companyName}}"/>

              <ng-template #showText>
                <h6>{{ advertiser.companyName }}</h6>
              </ng-template>

            </div>
          </ng-template>


        </ng-container>
      </div>
    </ng-template>

  </ng-container>

</div>
