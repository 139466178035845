import {MockDataInterface} from './mock-data.interface';
import {poniesMockData} from './data-sets/ponies/ponies';
import {customersMockData} from './data-sets/customers';
import {advertisersMockData} from './data-sets/advertisers/advertisers';
import {friendsOfTheAuctionMockData} from './data-sets/friends-of-the-auction';
import {staticDataMockData} from './data-sets/static-data';
import {staticAuctionEditions} from './data-sets/auction-editions';
import {cmsEntriesMockData} from './data-sets/cms-entries';
import {editionsMockData} from './data-sets/editions';
import {usersMockData} from './data-sets/users';

export function getMockData(loggedInUserId?: string): MockDataInterface {
  return {
    ponies: poniesMockData(loggedInUserId),
    customers: customersMockData(loggedInUserId),
    advertisers: advertisersMockData,
    friendsOfTheAuction: friendsOfTheAuctionMockData,
    staticData: staticDataMockData,
    auctionEditions: staticAuctionEditions,
    cmsEntries: cmsEntriesMockData,
    editions: editionsMockData,
    users: usersMockData,
  }
}
