import {Pipe, PipeTransform} from '@angular/core';
import {CmsEntriesService} from '../../services/cms-entries.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Pipe({
  name: 'getRenderedCmsEntryAsText',
  standalone: true
})
export class GetRenderedCmsEntryAsTextPipe implements PipeTransform {

  constructor(
    private cmsEntriesService: CmsEntriesService
  ) {
  }

  transform(key: string): Observable<string | null> {
    return this.cmsEntriesService.getItem(key).pipe(map(entry => entry?.value || null));
  }

}
