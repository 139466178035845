import {marker} from '@biesbjerg/ngx-translate-extract-marker';

export type AppMenuItem = {
  routerLink: string;
  icon: string;
  label: string;
  translateLabel?: boolean;
  loggedInUserRequired?: boolean;
  preDivider?: string;
  children?: AppMenuItem[];
  staticDataKeyForCount?: 'foals' | 'adolescents' | 'adults';
  fullPath?: string;
};

export const APP_ROUTES = {
  apiOffLine: 'api-off-line',
  private: 'beheer',
  dashboards: 'dashboards',
  ponies: 'ponies',
  customers: 'klanten',
  manage: 'manage',
  sponsors: 'sponsors',
  cms: 'cms',
  users: 'users',
  auction: 'veiling',
  list: 'overzicht',
  catalogueNumbers: 'volgnummers',
  currentYear: 'huidig-jaar',
  afterSales: 'after-sales',
  accommodations: 'overnachtingen',
  pastEditions: 'eerdere-edities',
  termsAndConditions: 'voorwaarden',
  register: 'aanmelden',
  news: 'nieuws',
  aboutUs: 'over-ons',
  supportUs: 'steun-ons',
  advertisers: 'adverteerders',
  friendsOfTheAuction: 'vrienden-van-de-veiling',
  shetlandPonySelectSale: 'shetland-pony-select-sale',
  history: 'historie',
  create: 'aanmaken',
  foals: 'veulens',
  adolescents: 'jeugd',
  adults: 'volwassen',
  bids: 'biedingen',
} as const;

export type PonyAge = typeof APP_ROUTES.adults | typeof APP_ROUTES.foals | typeof APP_ROUTES.adolescents;

export const AppMenuItems: AppMenuItem[]  = [
  // Private
  {
    routerLink: `${APP_ROUTES.private}/${APP_ROUTES.ponies}`,
    icon: 'settings',
    label: 'Pony\'s',
    loggedInUserRequired: true,
    children: [
      {
        routerLink: `${APP_ROUTES.list}`,
        icon: 'toc',
        label: 'Overzicht',
        loggedInUserRequired: true,
      },
      {
        routerLink: `${APP_ROUTES.catalogueNumbers}`,
        icon: 'discount',
        label: 'Volgnummers',
        loggedInUserRequired: true,
      },
      {
        routerLink: `${APP_ROUTES.bids}`,
        icon: 'price_change',
        label: 'Biedingen',
        loggedInUserRequired: true,
      }
    ],
  },
  {
    routerLink: `${APP_ROUTES.private}/${APP_ROUTES.customers}`,
    icon: 'people',
    label: 'Klanten',
    loggedInUserRequired: true,
  },
  {
    routerLink: `${APP_ROUTES.private}/${APP_ROUTES.manage}`,
    icon: 'tune',
    label: 'Beheer',
    loggedInUserRequired: true,
    children: [
      {
        routerLink: `${APP_ROUTES.sponsors}`,
        icon: 'support',
        label: 'Adverteerders & VVdV',
        loggedInUserRequired: true,
      },
      {
        routerLink: `${APP_ROUTES.cms}`,
        icon: 'edit_note',
        label: 'CMS',
        loggedInUserRequired: true,
      },
      {
        routerLink: `${APP_ROUTES.users}`,
        icon: 'persons',
        label: 'Gebruikers',
        loggedInUserRequired: true,
      }
    ],
  },
  // Public
  {
    routerLink: `${APP_ROUTES.auction}`,
    icon: 'gavel',
    label: marker('generic.Veiling'),
    translateLabel: true,
    children: [
      {
        routerLink: `${APP_ROUTES.currentYear}`,
        icon: 'verified',
        label: `${new Date().getFullYear()}`,
      },
      {
        routerLink: `${APP_ROUTES.foals}`,
        icon: 'grid_view',
        label: marker('generic.Veulens'),
        translateLabel: true,
        preDivider: marker('generic.Pony\'s'),
        staticDataKeyForCount: 'foals',
      },
      {
        routerLink: `${APP_ROUTES.adolescents}`,
        icon: 'grid_view',
        label: marker('generic.Jeugd'),
        translateLabel: true,
        staticDataKeyForCount: 'adolescents',
      },
      {
        routerLink: `${APP_ROUTES.adults}`,
        icon: 'grid_view',
        label: marker('generic.Volwassen'),
        translateLabel: true,
        staticDataKeyForCount: 'adults',
      },
      {
        routerLink: `${APP_ROUTES.register}`,
        icon: 'label_important',
        label: marker('generic.Aanmelden'),
        translateLabel: true,
      },
      {
        routerLink: `${APP_ROUTES.afterSales}`,
        icon: 'support_agent',
        label: marker('generic.After sales'),
        translateLabel: true,
        preDivider: '',
      },
      {
        routerLink: `${APP_ROUTES.accommodations}`,
        icon: 'hotel',
        label: marker('generic.Accommodaties'),
        translateLabel: true,
      },
      {
        routerLink: `${APP_ROUTES.pastEditions}`,
        icon: 'event_repeat',
        label: marker('generic.Voorgaande edities'),
        translateLabel: true,
      },
      {
        routerLink: `${APP_ROUTES.termsAndConditions}`,
        icon: 'policy',
        label: marker('generic.Veilingvoorwaarden'),
        translateLabel: true,
      },
    ]
  },
  {
    routerLink: `${APP_ROUTES.news}`,
    icon: 'newspaper',
    label: marker('generic.Nieuws'),
    translateLabel: true
  },
  {
    routerLink: `${APP_ROUTES.aboutUs}`,
    icon: 'info',
    label: marker('generic.Over ons'),
    translateLabel: true,
    children: [
      {
        routerLink: `${APP_ROUTES.shetlandPonySelectSale}`,
        icon: 'contact_mail',
        label: 'Shetland Pony Select Sale',
        translateLabel: false,
      },
      {
        routerLink: `${APP_ROUTES.history}`,
        icon: 'history_edu',
        label: marker('generic.Geschiedenis'),
        translateLabel: true,
      },
    ]
  },
  {
    routerLink: `${APP_ROUTES.supportUs}`,
    icon: 'support',
    label: marker('generic.Steun ons'),
    translateLabel: true,
    children: [
      {
        routerLink: `${APP_ROUTES.advertisers}`,
        icon: 'featured_video',
        label: marker('generic.Adverteerders'),
        translateLabel: true,
      },
      {
        routerLink: `${APP_ROUTES.friendsOfTheAuction}`,
        icon: 'volunteer_activism',
        label: marker('generic.Vrienden v/d veiling'),
        translateLabel: true,
      },
    ]
  }
];