import {Component, ElementRef, OnInit} from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import {DialogHeaderComponent} from '../dialog-header/dialog-header.component';
import {UserService} from '../../services/user.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {User} from '../../types/user.interface';
import {MockHttpClientPipe} from '../../pipes/mock-data-fetch.pipe';
import {HttpHeaders} from '@angular/common/http';
import {MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {APP_ROUTES} from '../../app.navigation';
import {environment} from '../../../environments/environment';
import {Subscription} from 'rxjs';
import {UseMockDataService} from '../../services/use-mock-data.service';
import {AutofocusDirective} from '../../directives/autofocus.directive';
import {InfoPaneComponent} from '../info-pane/info-pane.component';
import {getMockData} from '../../../mock-data/mock-data';

@Component({
  selector: 'app-login-dialog',
  standalone: true,
  imports: [SharedModule, DialogHeaderComponent, AutofocusDirective, InfoPaneComponent],
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss']
})
export class LoginDialogComponent implements OnInit {
  protected readonly environment = environment;

  showErrorMessage = false;
  loginIsPending = false;
  loginForm = new FormGroup({
    email: new FormControl<string>('', [Validators.required, Validators.email]),
    password: new FormControl<string>('', [Validators.required, Validators.min(3)]),
  });

  mockDataForm?: FormGroup;
  mockDataForm$?: Subscription;

  constructor(
    private dialogRef: MatDialogRef<LoginDialogComponent>,
    private elementRef: ElementRef,
    private mockHttpClientPipe: MockHttpClientPipe,
    private useMockDataService: UseMockDataService,
    private router: Router,
    public userService: UserService,
  ) {
  }

  ngOnInit(): void {
    this.initMockDataForm();
  }

  private initMockDataForm() {
    if (!this.environment.features.canUseMockData) return;

    this.mockDataForm = new FormGroup({
      useMockData: new FormControl(this.useMockDataService.getUseMockData())
    });

    this.mockDataForm$ = this.mockDataForm.valueChanges.subscribe(form => {
      this.useMockDataService.setUseMockData(form.useMockData);
      this.userService.unSetUser();
      location.reload();
    });
  }

  onLogin() {
    if (this.loginForm.invalid) return;
    this.loginIsPending = true;
    const formValues = this.loginForm.getRawValue();
    this.mockHttpClientPipe.transform({
      method: 'POST',
      url: `login`,
      comment: 'Gebruiker aanmelden',
      elementRef: this.elementRef.nativeElement,
      headers: {
        names: ['Authorization'],
        headers: new HttpHeaders().set('Authorization', `Basic ${btoa(formValues.email + ':' + formValues.password)}`)
      },
      delay: 800,
      mockReturnData: this.getMockUser(formValues.email!),
    }).subscribe({
      next: (newUser) => {
        this.loginIsPending = false;
        if (newUser) {
          this.showErrorMessage = false;
          this.userService.setUser(newUser);
          this.router.navigateByUrl(`/${APP_ROUTES.private}`).then(() => {
            this.dialogRef.close();
          });
        } else {
          this.handleLoginError()
        }
      },
      error: () => this.handleLoginError()
    });
  }

  private handleLoginError() {
    this.loginIsPending = false;
    this.showErrorMessage = true;
  }

  private getMockUser(email: string): User | null {
    if (getMockData(this.userService.getUser()?.id).users?.length > 0) {
      return getMockData(this.userService.getUser()?.id).users.find(user => user.email === email) || null;
    } else {
      return null;
    }
  }

  onReloadClick() {
    location.reload();
  }

  onLogout() {
    this.userService.unSetUser();
  }
}
