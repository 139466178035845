<mat-dialog-content>

  <app-dialog-header title="{{ advertiser?.companyName }}"></app-dialog-header>

  <img *ngIf="(advertiser?.logoPath || advertiser?.logoBase64) as logo"
       src="{{logo}}"
       alt="{{advertiser?.companyName}}">

  <p>
    <ng-container *ngIf="advertiser?.website as website">
      <b>{{ 'generic.Website' | translate }}</b><br /><a href="{{website}}" target="_blank">{{ website }}</a><br><br>
    </ng-container>

    <ng-container *ngIf="advertiser?.email as email">
      <b>{{ 'generic.E-mail' | translate }}</b><br><a href="mailto:{{email}}">{{ email }}</a><br><br>
    </ng-container>

    <ng-container *ngIf="advertiser?.phoneNumber as phoneNumber">
      <b>{{ 'generic.Telefoonnummer' | translate }}</b><br><a href="tel:{{phoneNumber}}">{{ phoneNumber }}</a><br><br>
    </ng-container>

    <ng-container *ngIf="advertiser?.city as city">
      <b>{{ 'generic.Plaats' | translate }}</b><br>{{ city }}<br><br>
    </ng-container>
  </p>

</mat-dialog-content>

