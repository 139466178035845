import {StaticData} from '../../app/services/static-data.service';
import * as moment from 'moment';

export const staticDataMockData = {
  foals: 0,
  ponyIsFoalWhenBornAfterDate: moment('01-01-2025').toDate(),
  adolescents: 2,
  adults: 2,
  ponyIsAdultWhenBornBeforeDate: moment('01-01-2023').toDate(),
} as StaticData;
