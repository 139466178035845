import {Edition} from '../../app/types/edition.interface';
import * as moment from 'moment';

export const editionsMockData: Edition[] = [
  {
    id: '123',
    deadlineDate: moment('09-08-2025 00:00').toDate(),
    viewDate: moment('10-08-2025 09:00').toDate(),
    auctionDate: moment('10-08-2025 10:00').toDate(),
    edition: 14
  }
];
