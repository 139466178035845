import {AdTypes, Advertiser} from '../../../app/types/advertiser.interface';
import {advertisersKoltechLogoBase64} from './advertisers-koltech-logo-base64';
import {advertisersTuincentrumLogoBase64} from './advertisers-tuincentrum-logo-base64';
import {advertisersKairosLogoBase64} from './advertisers-kairos-logo-base64';
import {advertisersHavensLogoBase64} from './advertisers-havens-logo-base64';
import {advertiserPdfBase64_01} from './advertiser-pdf-base-64_01';

export const advertisersMockData: Advertiser[] = [
  {
    id: '123',
    email: 'jbausch@ziggo.nl',
    companyName: 'Stal Ankeveen',
    address: 'Stichts End 78',
    zipcode: '0000AA',
    city: 'Ankeveen',
    phoneNumber: '+31622454520',
    website: 'https://maps.app.goo.gl/pmj7KA8etNAiVeGi6',
    remarks: 'Ik vond het een duidelijk formulier',
    colorType: AdTypes.COLOR,
    pageType: AdTypes.FULL_PAGE,
    advertPrice: 100,
    advertiserSubmittedPDF: true,
    pdfFileName: 'drukbestand.pdf',
    pdfBase64: advertiserPdfBase64_01,
    isApproved: true,
  },
  {
    id: '2222222',
    email: 'info@stalvankairos.com',
    companyName: 'Stal van Kairos',
    address: 'Wilgenroosweg 1',
    zipcode: '5757RC',
    city: 'Liessel',
    phoneNumber: '+31646162858',
    website: 'https://www.stalvankairos.com/',
    colorType: AdTypes.BLACK_AND_WHITE,
    pageType: AdTypes.FULL_PAGE,
    advertPrice: 75,
    logoFileName: 'kairos.jpg',
    logoBase64: advertisersKairosLogoBase64,
    isApproved: true,
  },
  {
    id: '321',
    email: 'info@tuincentrumoosterhout.nl',
    companyName: 'Tuincentrum Oosterhout',
    address: 'Damweg 7',
    zipcode: '4905BS',
    city: 'Oosterhout',
    phoneNumber: '+31162451852',
    website: 'https://www.tuincentrumoosterhout.nl/',
    colorType: AdTypes.BLACK_AND_WHITE,
    pageType: AdTypes.HALF_PAGE,
    advertPrice: 50,
    advertiserSubmittedPDF: false,
    logoFileName: 'logo_tuincentrum.jpg',
    logoBase64: advertisersTuincentrumLogoBase64,
    isApproved: true,
  },
  {
    id: '3322',
    email: 'info@koltec.nl',
    companyName: 'Koltec BV',
    address: 'SPINVELD 39',
    zipcode: '4815 HV',
    city: 'Breda',
    phoneNumber: '+31765297575',
    website: 'https://www.koltec.nl',
    colorType: AdTypes.COLOR,
    pageType: AdTypes.HALF_PAGE,
    advertPrice: 75,
    advertiserSubmittedPDF: false,
    logoFileName: 'koltech.jpg',
    logoBase64: advertisersKoltechLogoBase64,
    isApproved: true,
  },
  {
    id: '323232',
    email: 'info@havens.nl',
    companyName: 'Havens',
    address: 'Kalverstraat 2a',
    zipcode: '5823 AK',
    city: 'Maashees',
    phoneNumber: '+31(0)478-638238',
    website: 'https://www.havens.nl/',
    colorType: AdTypes.BLACK_AND_WHITE,
    pageType: AdTypes.FULL_PAGE,
    advertPrice: 75,
    advertiserSubmittedPDF: false,
    logoFileName: 'logo-havens.jpg',
    logoBase64: advertisersHavensLogoBase64,
    isApproved: true,
  },
];
