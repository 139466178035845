
import {APP_ROUTES} from '../../app/app.navigation';
import {CMSEntry} from '../../app/types/cms-entry.interface';
import {GlobalCMSPageId} from '../../app/private/pages/manage/pages/cms/cms-pages';

export const cmsEntriesMockData: CMSEntry[] = [
  // Global
  {
    id: 'global-phone-number',
    cmsPageId: GlobalCMSPageId,
    label: 'Telefoonnummer Secretariaat',
    description: 'Wordt gebruikt in footer op alle pagina\'s. En op de Over ons -> Shetland Pony Select Sale pagina',
    type: 'LINE',
    values: {
      nl: '+316 108 77 926',
      en: null
    },
  },
  {
    id: 'global-email',
    cmsPageId: GlobalCMSPageId,
    label: 'Email adres Secretariaat',
    type: 'LINE',
    description: 'Wordt gebruikt in footer op alle pagina\'s. En op de Over ons -> Shetland Pony Select Sale pagina',
    values: {
      nl: 'informatie@shetlandponyselectsale.nl',
      en: null
    },
  },
  {
    id: 'global-iban',
    cmsPageId: GlobalCMSPageId,
    label: 'IBAN',
    type: 'LINE',
    description: 'Wordt gebruikt in de VVdV- en Adverteerder formulieren. En op de Over ons -> Shetland Pony Select Sale pagina',
    values: {
      nl: 'NL69 RABO 0106582607',
      en: null
    },
  },
  // After Sales
  {
    id: APP_ROUTES.afterSales,
    cmsPageId: APP_ROUTES.afterSales,
    label: 'After Sales - Info',
    type: 'RICH',
    values: {
      nl: '<h2>After Sales</h2><p><b>Voor de mensen die van BUITEN Nederland komen en die op de veiling één of meerdere pony\'s hebben gekocht, bieden we als organisatie BEPERKT diverse vormen van After Sales Service.</b></p><h4>Tijdelijk verblijf</h4><p>U kunt de pony na aankoop in overleg tijdelijk (op eigen risico) stallen bij een aantal Nederlandse fokkers die ze beschikbaar hebben gesteld aan de veilingorganisatie. Afhankelijk van uw terugreis bekijken we welke mogelijkheid het meest geschikt is. Dit verblijf is op de veiling te regelen of vooraf te reserveren uitsluitend via Jan Bausch bereikbaar op nummer 0031622454520 . Uw pony verblijft in een ruime stal en krijgt een uitstekende verzorging. De kosten hiervoor zijn 15 euro per dag. Merries met veulen aan de voet kosten €17,50 per dag. </p><h4>Exportdocumenten</h4><p>Er is een stamboekinspecteur aanwezig om aan het eind van de dag (op verzoek en tegen betaling) de door buitenlanders aangekochte dieren te inspecteren ten behoeve van het gereedmaken van het ITC exportdocument van het NSPS (kosten document € 31,50) van het NSPS.</p>',
      en: '<h2>After Sales</h2><p><b>For people coming from OUTSIDE the Netherlands who have purchased one or more ponies at the auction, we as an organization offer LIMITED various forms of After Sales Service.</b></p><h4>Temporary Stay</h4><p>You can temporarily (at your own risk) stable the pony after purchase with several Dutch breeders who have made their facilities available to the auction organization. Depending on your return trip, we will determine which option is most suitable. This stay can be arranged at the auction or reserved in advance exclusively through Jan Bausch, reachable at 0031622454520. Your pony will stay in a spacious stable and receive excellent care. The cost is 15 euros per day. Mares with a foal at foot cost €17.50 per day.</p><h4>Export Documents</h4><p>A studbook inspector will be present at the end of the day (upon request and for a fee) to inspect animals purchased by foreigners in preparation for the NSPS ITC export document (document cost €31.50).</p>',
    },
  },
  // News
  {
    id: APP_ROUTES.news + '_image',
    cmsPageId: APP_ROUTES.news,
    type: 'IMAGE',
    label: 'Pagina afbeelding',
    values: {
      nl: 'https://images.spss.nu/mock-images/ponies/04.jpeg',
      en: null,
    },
  },
  {
    id: APP_ROUTES.news + '_info',
    cmsPageId: APP_ROUTES.news,
    label: 'Niews - Artikelen',
    type: 'RICH',
    values: {
      nl: '<h2>Gelukkig nieuwjaar</h2><h3>1 januari 2025</h3><p>Beste wensen!</p><h2>Groot onderhoud</h2><h3>12 december 2024</h3><p>We zijn druk bezig</p><ul><li>Test</li><li>Test</li></ul><p>Nog een regel</p><ol><li>Test2</li><li>Test2</li></ol>',
      en: null,
    },
  },
  // About us -> SPSS
  {
    id: `${APP_ROUTES.shetlandPonySelectSale}_image`,
    cmsPageId: APP_ROUTES.shetlandPonySelectSale,
    label: 'Pagina afbeelding',
    type: 'IMAGE',
    values: {
      nl: 'https://images.spss.nu/mock-images/ponies/02.jpeg',
      en: null
    },
  },
  {
    id: `${APP_ROUTES.shetlandPonySelectSale}_address`,
    cmsPageId: APP_ROUTES.shetlandPonySelectSale,
    label: 'Adres Secretariaat',
    type: 'BLOCK',
    values: {
      nl: 'Shetland Pony Select Sale\np/a Loofaert 28a\n5258 SN BERLICUM (NL)',
      en: null
    },
  },
  {
    id: `${APP_ROUTES.shetlandPonySelectSale}_kvk`,
    cmsPageId: APP_ROUTES.shetlandPonySelectSale,
    label: 'KVK Nummer',
    type: 'LINE',
    values: {
      nl: '7277233',
      en: null
    },
  },
  {
    id: `${APP_ROUTES.shetlandPonySelectSale}_auction-location-name`,
    cmsPageId: APP_ROUTES.shetlandPonySelectSale,
    label: 'Veilinglocatie - Naam',
    type: 'LINE',
    values: {
      nl: 'Stichting de Cavaliers Helden',
      en: null
    },
  },
  {
    id: `${APP_ROUTES.shetlandPonySelectSale}_auction-location-address`,
    cmsPageId: APP_ROUTES.shetlandPonySelectSale,
    label: 'Veilinglocatie - Locatie',
    type: 'BLOCK',
    values: {
      nl: 'Ninnesweg 176\n5981 PD Panningen\n(Peel en Maas)',
      en: null
    },
  },
  {
    id: `${APP_ROUTES.shetlandPonySelectSale}_auction-phone-number`,
    cmsPageId: APP_ROUTES.shetlandPonySelectSale,
    label: 'Veilinglocatie - Telefoon',
    type: 'LINE',
    values: {
      nl: '+31773074407',
      en: null
    },
  },
  {
    id: `${APP_ROUTES.shetlandPonySelectSale}_info`,
    cmsPageId: APP_ROUTES.shetlandPonySelectSale,
    label: 'Veilinglocatie - Info',
    type: 'RICH',
    values: {
      nl: '<h3>Shetland Pony Select Sale</h3><p><b>De Stichting Shetland Pony Select Sale is begin 2010 opgericht ter bevordering van de verkoop en het promoten van de Shetlandpony. De stichting is ingeschreven bij de Kamer van Koophandel en is een stichting ZONDER winstoogmerk!</b><br><br>Het bestuur van de Stichting Shetland Pony Select Sale bestaat uit:</p><ul><li>Jan Bausch; Voorzitter</li><li>Hein van Roij; Penningmeester</li><li>Maria van Driel; Catering</li><li>Annette van Boxtel; Secretaris</li><li>Ruud van Raak; Selectie, PR, Logistiek, Veilingmeester</li><li>Sjef Damen; Veilingaccommodatie</li><li>Henk de Grouw; Veilingaccomodatie</li></ul><h4>Dankzij onze vrijwilligers</h4><p>De organisatie wordt in de voorbereidingen en op de veilingdag ondersteund door een enorme groep enthousiaste vrijwilligers.</p><h4>Officieel notariskantoor</h4><p>Daarnaast is er een notariskantoor aanwezig (Kern Notarissen) om toe te zien dat alles verloopt volgens de reglementen.</p>',
      en: '<h3>Shetland Pony Select Sale</h3><p><b>The Shetland Pony Select Sale Foundation was established in early 2010 to promote the sale and promotion of Shetland ponies. The foundation is registered with the Chamber of Commerce and is a non-profit foundation!</b><br><br>The board of the Shetland Pony Select Sale Foundation consists of:</p><ul><li>Jan Bausch; Chairman</li><li>Hein van Roij; Treasurer</li><li>Maria van Driel; Catering</li><li>Annette van Boxtel; Secretary</li><li>Ruud van Raak; Selection, PR, Logistics, Auctioneer</li><li>Sjef Damen; Auction venue</li><li>Henk de Grouw; Auction venue</li></ul><h4>Thanks to our volunteers</h4><p>The organization is supported by a large group of enthusiastic volunteers in the preparations and on the auction day.</p><h4>Official notary office</h4><p>Additionally, there is a notary office present (Kern Notarissen) to ensure everything runs according to the regulations.</p>'
    },
  },
];
