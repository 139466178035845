import {User, UserRoleEnum} from '../../app/types/user.interface';

export const usersMockData: User[] = [
  {
    id: '999-999-999',
    email: 'r.vanroij@dysto.nl',
    name: 'Robin van Roij',
    role: UserRoleEnum.SUPER_ADMIN
  },
  {
    id: '888-888-888',
    email: 'sander@sanderottens.com',
    name: 'Sander Ottens',
    role: UserRoleEnum.SUPER_ADMIN
  },
  {
    id: '777-777-777',
    email: 'hein.van.Roij@enexis.nl',
    name: 'Hein van Roij',
    role: UserRoleEnum.ADMIN
  }
];
