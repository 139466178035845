import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {MockHttpClientPipe} from '../../pipes/mock-data-fetch.pipe';
import {Advertiser} from '../../types/advertiser.interface';
import {getMockData} from '../../../mock-data/mock-data';

@Injectable({
  providedIn: 'root'
})
export class PublicAdvertisersService {
  private initStarted = false;
  private advertisers$ = new BehaviorSubject<Advertiser[] | null>(null);

  constructor(
    private mockHttpClientPipe: MockHttpClientPipe
  ) {
  }

  init() {
    if (this.initStarted) return;
    this.initStarted = true;
    this.getAdvertisersFromApi();
  }

  private getAdvertisersFromApi() {
    const mockAdvertisers = getMockData()?.advertisers || [];
    this.mockHttpClientPipe.transform<Advertiser[]>({
      method: 'GET',
      url: `public/advertisers`,
      comment: 'Alle sponsoren ophalen, let op; beperkte properties mee geven. En alleen de sponsoren waar isApproved == true',
      mockReturnData: mockAdvertisers ? mockAdvertisers.map(advertiser => {
        return {
          email: advertiser.email,
          companyName: advertiser.companyName,
          address: advertiser.address,
          zipcode: advertiser.zipcode,
          city: advertiser.city,
          phoneNumber: advertiser.phoneNumber,
          website: advertiser.website,
          logoBase64: advertiser.logoBase64,
          logoPath: advertiser.logoPath,
        } as Advertiser
      }) : []
    }).subscribe(advertisers => this.advertisers$.next(advertisers));
  }

  getAdvertisers() {
    return this.advertisers$;
  }
}
