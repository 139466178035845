import {Customer} from '../../app/types/customer.interface';
import {User} from '../../app/types/user.interface';
import {usersMockData} from './users';

const LOGGED_IN_USER_ID = 'LOGGED_IN_USER_ID';
const LOGGED_IN_USER_NAME = 'LOGGED_IN_USER_NAME';
const OTHER_USER_ID = 'OTHER_USER_ID';
const OTHER_USER_NAME = 'OTHER_USER_NAME';

export const createdMockCustomers: Customer[] = [
  {
    id: '123',
    firstName: 'Jan',
    lastName: 'Janssen',
    email: 'klant_1@example.com',
    phoneNumber: '0612345678',
    bankDetails: 'NL12ABCD0123456789',
    country: 'Nederland',
    city: 'Amsterdam',
    postalCode: '1234 AA',
    street: 'Amsterdamsestraatweg',
    houseNumber: '12',
    houseNumberAddition: 'A',
    lockedByUserId: null,
    lockedByUserName: null,
    costsBenefitsViaBank: null,
    costsBenefitsViaCash: null,
    invoiceUrl: '/assets/pdf/customer-invoice.pdf',
    poniesSoldCount: 0,
    poniesBoughtCount: 1,
    benefitsTotal: 0,
    costSubTotal: 0,
    costAuctionAddedCosts: 0,
    costTotal: 0,
    costBenefitsTotal: 0,
    hasPaymentsPending: false,
    auctionAgreementPDFUrl: '/assets/pdf/auction-agreement.pdf',
    auctionAgreementExcelUrl: '/assets/excel/auction-agreement.xlsx',
  },
  {
    id: '321',
    firstName: 'Johanna',
    lastName: 'Willemsen',
    email: 'klant_2@example.com',
    phoneNumber: '0612345678',
    bankDetails: 'NL12ABCD0123456789',
    country: 'België',
    city: 'Brussel',
    postalCode: '5448 BW',
    street: 'Brusselsestraat',
    houseNumber: '875',
    houseNumberAddition: undefined,
    lockedByUserId: OTHER_USER_ID,
    lockedByUserName: OTHER_USER_NAME,
    costsBenefitsViaBank: 600,
    costsBenefitsViaCash: 150,
    invoiceUrl: '/assets/pdf/customer-invoice.pdf',
    poniesSoldCount: 1,
    poniesBoughtCount: 0,
    benefitsTotal: 400,
    costSubTotal: 0,
    costAuctionAddedCosts: 0,
    costTotal: 0,
    costBenefitsTotal: 400,
    hasPaymentsPending: false,
  },
  {
    id: '878',
    firstName: 'Philippe',
    lastName: 'de la Fontaine',
    email: 'klant_3@example.com',
    phoneNumber: '0612345678',
    bankDetails: 'NL12ABCD0123456789',
    country: 'Frankrijk',
    city: 'Parijs',
    postalCode: '4578 AA',
    street: 'Parijsestraat',
    houseNumber: '445',
    houseNumberAddition: undefined,
    lockedByUserId: LOGGED_IN_USER_ID,
    lockedByUserName: LOGGED_IN_USER_NAME,
    costsBenefitsViaBank: null,
    costsBenefitsViaCash: null,
    invoiceUrl: '/assets/pdf/customer-invoice.pdf',
    poniesSoldCount: 1,
    poniesBoughtCount: 1,
    benefitsTotal: 100,
    costSubTotal: 200,
    costAuctionAddedCosts: 12,
    costTotal: 212,
    costBenefitsTotal: -112,
    hasPaymentsPending: true,
  },
];

export function customersMockData(loggedInUserId?: string): Customer[] {
  if (!loggedInUserId) return createdMockCustomers || [];
  const loggedInUser: User | undefined = (usersMockData || []).find((user) => user.id === loggedInUserId);
  const otherUser: User | undefined = (usersMockData || []).find((user) => user.id !== loggedInUserId);
  if (!otherUser || !loggedInUser) return createdMockCustomers || [];

  return (createdMockCustomers || []).map(customer => {
    if (customer.lockedByUserId === LOGGED_IN_USER_ID) customer.lockedByUserId = loggedInUser.id;
    if (customer.lockedByUserName === LOGGED_IN_USER_NAME) customer.lockedByUserId = loggedInUser.name;
    if (customer.lockedByUserId === OTHER_USER_ID) customer.lockedByUserId = otherUser.id;
    if (customer.lockedByUserName === OTHER_USER_ID) customer.lockedByUserId = otherUser.name;
    return customer
  });
}
