import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import * as Sentry from'@sentry/angular';
import * as moment from 'moment';

let totalServiceWorkers = 0;
let totalServiceWorkersUnregistered = 0;
let swFound = false;

if(environment.isProduction) {
  Sentry.init({
    dsn: 'https://ebc7ff0f81886df319e83843ef13b7bc@o4508683990794240.ingest.de.sentry.io/4508683992236112',
    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      Sentry.browserTracingIntegration(),
      // Registers the Replay integration,
      // which automatically captures Session Replays
      Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // We recommend adjusting this value in production
    // Learn more at
    // https://docs.sentry.io/platforms/javascript/configuration/options/#traces-sample-rate
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: ['localhost', `$${environment.apiBaseUrl}/${environment.apiPrefix}`],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    // Learn more at
    // https://docs.sentry.io/platforms/javascript/session-replay/configuration/#general-integration-configuration
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const installAndUpdateCustomSw = () => {
  if (swFound) return;
  navigator.serviceWorker.register('app-service-worker.js', {scope: '/'}).then(function (registration) {
    console.info('app-service-worker.js registered');
  }, function (err) {
    console.info('app-service-worker.js registration failed: ', err);
  });
}

const onUnregisteredServiceWorker = () => {
  totalServiceWorkersUnregistered++;
  console.info(`Service worker ${totalServiceWorkersUnregistered} of ${totalServiceWorkers} deleted`);

  if (totalServiceWorkers !== totalServiceWorkersUnregistered) return;
  installAndUpdateCustomSw();
}

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    if (registrations.length) {
      totalServiceWorkers = registrations.length;
      for (let registration of registrations) {
        const scriptUrl = registration?.active?.scriptURL || '';
        if (scriptUrl.split('/app-service-worker.js').length > 1) {
          swFound = true;
          registration.update().then(() => {
            console.info('app-service-worker.js updated');
          });
        } else {
          registration.unregister().then(() => {
            console.info('unregistered service worker:', registration);
            onUnregisteredServiceWorker()
          });
        }
      }
    } else {
      installAndUpdateCustomSw();
    }
  });
}

if (environment.isProduction) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

Date.prototype.toJSON = function () {
  return moment(this).format();
};
