import {APP_INITIALIZER, DEFAULT_CURRENCY_CODE, ErrorHandler, LOCALE_ID} from '@angular/core';
import {AppComponent} from './app.component';
import {BrowserModule} from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {AppRouting} from './app.routing';
import {ApiInterceptor} from './api-interceptor.service';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {MAT_DIALOG_DEFAULT_OPTIONS} from '@angular/material/dialog';
import {DatePipe, DecimalPipe, JsonPipe, NgOptimizedImage, registerLocaleData} from '@angular/common';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MAT_NATIVE_DATE_FORMATS} from '@angular/material/core';
import {CustomDateAdapter} from './custom-date-adapter';
import {VersionCheckerComponent} from './version-checker.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MAT_PAGINATOR_DEFAULT_OPTIONS, MatPaginatorIntl} from '@angular/material/paginator';
import localeEn from '@angular/common/locales/en-GB';
import localeNl from '@angular/common/locales/nl';
import {environment} from '../environments/environment';
import {NgModule} from '@angular/core';
import {MockHttpClientPipe} from './pipes/mock-data-fetch.pipe';
import {UseMockDataService} from './services/use-mock-data.service';
import {UserService} from './services/user.service';
import {UserGuard} from './guards/user.guard';
import {SharedModule} from './shared/shared.module';
import {WebSocketService} from './services/web-socket.service';
import {WsUsersComponent} from './components/ws-users/ws-users.component';
import {TranslateLoader, TranslateModule, TranslatePipe} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {ToolbarMenuComponent} from './components/toolbar-menu/toolbar-menu.component';
import {MatListModule} from '@angular/material/list';
import {MatSidenavModule} from '@angular/material/sidenav';
import {SidenavMenuComponent} from './components/sidenav-menu/sidenav-menu.component';
import {PublicFooterComponent} from './public/components/public-footer/public-footer.component';
import {APP_LANGUAGE_LOCALE_IDS, APP_LANGUAGES, GetLocaleId} from './services/i18n.service';

import * as Sentry from "@sentry/angular";
import {Router} from '@angular/router';
import {MatDatepickerModule} from '@angular/material/datepicker';

function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(
    httpClient,
    '/assets/i18n/',
    `.json?cacheBuster=${environment.version}`
  );
}

function getProviders() {
  const providers: any[] = [
    // Services
    UseMockDataService,
    UserService,
    WebSocketService,

    // Pipes
    DecimalPipe,
    DatePipe,
    MockHttpClientPipe,
    JsonPipe,
    TranslatePipe,

    // Guards
    UserGuard,

    // Other providers
    {provide: DateAdapter, useClass: CustomDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: MAT_NATIVE_DATE_FORMATS},
    MatDatepickerModule,

    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true
    },
    {provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR'},
    {
      provide: LOCALE_ID,
      useValue: GetLocaleId()
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {subscriptSizing: 'dynamic'}
    },
    {
      provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
      useValue: {formFieldAppearance: 'fill'}
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        maxWidth: null,
        maxHeight: null,
        panelClass: 'app-dialog',
      }
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: GetLocaleId()
    },
    {
      provide: MatPaginatorIntl,
      useValue: Object.assign(new MatPaginatorIntl(), {
        itemsPerPageLabel: 'Per pagina',
        nextPageLabel: 'Volgende',
        previousPageLabel: 'Vorige',
        firstPageLabel: 'Eerste',
        lastPageLabel: 'Laatste',
        getRangeLabel: (page: number, pageSize: number, length: number) => {
          if (length === 0 || pageSize === 0) {
            return `0 van ${length}`;
          }
          length = Math.max(length, 0);
          const startIndex = page * pageSize;
          const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
          return `${startIndex + 1}–${endIndex}/${length}`;
        }
      })
    }
  ]

  if (environment.isProduction) {
    // SENTRY
    providers.concat([
      {
        provide: ErrorHandler,
        useValue: Sentry.createErrorHandler(),
      },
      {
        provide: Sentry.TraceService,
        deps: [Router],
      },
      {
        provide: APP_INITIALIZER,
        useFactory: () => () => {
        },
        deps: [Sentry.TraceService],
        multi: true,
      }] as any[]
    )
  }

  return providers;
}

@NgModule({
  declarations: [
    AppComponent,
    VersionCheckerComponent,
    WsUsersComponent
  ],
  imports: [
    // Angular
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),

    SharedModule,

    // Routing
    AppRouting,
    ToolbarMenuComponent,
    MatListModule,
    MatSidenavModule,
    SidenavMenuComponent,
    PublicFooterComponent,
    NgOptimizedImage,
  ],
  providers: getProviders(),
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor() {
    switch (GetLocaleId()) {
      case APP_LANGUAGE_LOCALE_IDS[APP_LANGUAGES[1]]:
        registerLocaleData(localeEn);
        break;
      default:
        registerLocaleData(localeNl)
        break;
    }
  }
}


