import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SharedModule} from '../../shared/shared.module';

export type InfoPaneStyle = 'calm' | 'notification' | 'warning' | 'positive';
export type InfoPaneSize = 'small' | 'med' | 'large';

@Component({
  selector: 'app-info-pane',
  templateUrl: './info-pane.component.html',
  styleUrls: ['./info-pane.component.scss'],
  standalone: true,
  imports: [
    SharedModule
  ]
})
export class InfoPaneComponent implements OnInit {
  @Input() text: string = '';
  @Input() icon: string = 'info';
  @Input() size: InfoPaneSize = 'med';
  @Input() infoPaneStyle: InfoPaneStyle = 'calm';
  @Input() canClose = false;
  @Input() closeIcon = 'close';

  @Output() onClose: EventEmitter<void> = new EventEmitter<void>();

  constructor() {
  }

  ngOnInit(): void {
  }

  onCloseClick() {
    if (this.onClose) {
      this.onClose.emit();
    }
  }

}
