import {Base} from './base.interface';

export enum UserRoleEnum {
  CUSTOMER = 0,
  ADMIN = 10,
  SUPER_ADMIN = 20
}

export const UserRoleLabels = {
  [`${UserRoleEnum.CUSTOMER}`]: 'Klant',
  [`${UserRoleEnum.ADMIN}`]: 'Admin',
  [`${UserRoleEnum.SUPER_ADMIN}`]: 'SuperAdmin',
}

export interface User extends Base {
  apiToken?: string;
  apiTokenExpireDate?: Date;
  email?: string;
  role: UserRoleEnum;
}
