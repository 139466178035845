import {Injectable} from '@angular/core';

import {BehaviorSubject, Observable} from 'rxjs';
import {User} from '../types/user.interface';
import {appConfig} from '../../app-config';
import {MockHttpClientPipe} from '../pipes/mock-data-fetch.pipe';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private _user: User | undefined;
  private user: BehaviorSubject<User | undefined>;

  user$: Observable<User | undefined>;

  constructor(
    private mockHttpClientPipe: MockHttpClientPipe,
    private matDialog: MatDialog,
    private router: Router,
  ) {
    this._user = undefined;
    this.user = new BehaviorSubject<User | undefined>(undefined);
    this.user$ = this.user.asObservable();
  }

  private triggerUpdate() {
    this.user.next(Object.assign({}, this._user));
  }

  setUser(user: User) {
    this._user = Object.assign({}, user);
    this.updateUser();
  }

  private updateUser() {
    localStorage.setItem(appConfig.localStorageKeys.user, JSON.stringify(this._user));
    this.triggerUpdate();
  }

  unSetUser() {
    if(!this._user) return;
    this._user = undefined;
    localStorage.removeItem(appConfig.localStorageKeys.user);
    this.user.next(undefined);

    this.matDialog.closeAll();
    this.router.navigateByUrl('/');

    this.mockHttpClientPipe.transform({
      method: 'POST',
      url: 'logout',
      comment: 'logout',
      mockReturnData: null,
    }).subscribe();
  }

  getUser(): User | undefined {
    return this._user;
  }

  setApiTokenExpireDate(apiTokenExpireDate: Date) {
    if (!this._user) return;
    this._user.apiTokenExpireDate = apiTokenExpireDate;
    this.updateUser();
  }
}
