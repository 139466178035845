import {APP_ROUTES, AppMenuItem, AppMenuItems} from '../../../../../app.navigation';
import {CMSPage} from '../../../../../types/cms-page.interface';

const flattenedAppMenuItems: AppMenuItem[] = [...AppMenuItems].reduce(
  (accumulator: AppMenuItem[], value) => {
    if (value?.children?.length) {
      return accumulator.concat(value.children.map(child => {
        child.fullPath = `${value.routerLink}/${child.routerLink}`;
        return child;
      }) || [])
    } else {
      value.fullPath = value.routerLink;
      return accumulator.concat(value)
    }
  }, []
);


const appMenuItemsForCMS: AppMenuItem[] = [
  flattenedAppMenuItems.find((item: AppMenuItem) => item.routerLink === APP_ROUTES.afterSales)!,
  flattenedAppMenuItems.find((item: AppMenuItem) => item.routerLink === APP_ROUTES.news)!,
  flattenedAppMenuItems.find((item: AppMenuItem) => item.routerLink === APP_ROUTES.shetlandPonySelectSale)!
];

export const GlobalCMSPageId = 'global';

const tempCMSPages: CMSPage[] = [
  {
    id: GlobalCMSPageId,
    title: 'Algemeen',
    icon: 'display_settings'
  },
]

appMenuItemsForCMS.forEach(item => {
  tempCMSPages.push({
    id: item.routerLink,
    title: item.label,
    icon: item.icon,
    fullPath: item.fullPath,
  })
});

export const CMSPages = [...tempCMSPages] as const;