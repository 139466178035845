import {MatDialogConfig} from '@angular/material/dialog';
import {
  FiltersCustomersTable
} from './app/private/components/customers-table/customers-table-filters-form/filters-tickets-table.class';

const breakpoints = [
  {name: 'watch', endsAt: 85},
  {name: 'mp', endsAt: 480},
  {name: 'ml', endsAt: 680},
  {name: 'tp', endsAt: 800},
  {name: 'tl', endsAt: 992},
  {name: 'd', endsAt: 1200},
  {name: 'dl', endsAt: 1600},
  {name: 'dxl', endsAt: 9999},
]
export type BreakPointRange = { min: number, max: number, name: string };
const breakPointRanges: BreakPointRange[] = [];
breakpoints.forEach((breakpoint, i) => {
  let min = 0;
  if (i > 0) {
    min = breakpoints[i - 1].endsAt + 1;
  }
  breakPointRanges.push({min: min, max: breakpoint.endsAt, name: breakpoint.name})
});

const fullScreenWiderDesktopDialogConfig = Object.assign(new MatDialogConfig(), {
  panelClass: ['app-dialog', 'full-screen-dialog--wider-desktop'],
  maxWidth: null,
  maxHeight: null
});

export const appConfig = {
  breakPointRanges: breakPointRanges as BreakPointRange[],
  fullScreenWiderDesktopDialogConfig: fullScreenWiderDesktopDialogConfig as MatDialogConfig,

  // LocalStorage
  localStorageKeys: {
    // Global
    language: 'language',
    lastSuccessFulNavigationUrl: 'lastSuccessFulNavigationUrl',
    urlToOpenAfterApiBackOnline: 'urlToOpenAfterApiBackOnline',
    user: 'user',
    useMockData: 'useMockData',
    // Pony Grid
    ponyGridSelectedOption: 'ponyGridSelectedOption',
    // Pony Detail
    ponyDetailOpenPanels: 'ponyDetailOpenPanels',
    // Customer Detail
    customerDetailOpenPanels: 'customerDetailOpenPanels',
    // Register pony form
    savedRegisterSellerData: 'savedRegisterSellerData',
    savedRegisterPoniesData: 'savedRegisterPoniesData',
    // Tables
    tables: {
      ponies: {
        columns: 'poniesTableColumns',
      },
      customers: {
        columns: 'customersTableColumns',
        filter: 'customersTableFilter',
      }
    },
    // CMS page
    lastOpenedCMSPage: 'lastOpenedCMSPage',
  },

  defaultTableColumns: {
    ponies: [
      {
        id: 'name',
        label: 'Naam'
      },
      {
        id: 'catalogueNumber',
        label: 'Volgnummer'
      },
      {
        id: 'isConfirmed',
        label: 'Bevestigd'
      },
      {
        id: 'isSelected',
        label: 'Geselecteerd'
      },
      {
        id: 'sellingPrice',
        label: 'Verkoopprijs'
      },
      {
        id: 'sellerCustomerLastName',
        label: 'Verkoper'
      },
      {
        id: 'fundsDisbursed',
        label: 'Uitbetaald'
      },
      {
        id: 'buyerCustomerLastName',
        label: 'Koper'
      },
      {
        id: 'fundsReceived',
        label: 'Afgerekend'
      },
      {
        id: '_purchaseAgreementReady',
        label: 'Koopovereenkomst'
      },
      {
        id: 'isVisibleOnPublicPages',
        label: 'Zichtbaar'
      },
      {
        id: 'photosCount',
        label: 'Foto\'s'
      },
      {
        id: 'familyTreePath',
        label: 'Stamboom'
      },
      {
        id: 'color',
        label: 'Kleur'
      },
      {
        id: 'size',
        label: 'Maat'
      },
      {
        id: 'firstPremiumCount',
        label: 'Premie'
      },
      {
        id: 'gender',
        label: 'Geslacht'
      },
      {
        id: 'pregnantType',
        label: 'Dragend'
      },
      {
        id: 'pregnantProofPath',
        label: 'Dekbewijs'
      },
      {
        id: 'scanProofPath',
        label: 'Scanbewijs'
      },
      {
        id: 'dateOfBirth',
        label: 'Categorie'
      },
      {
        id: 'admittanceDate',
        label: 'Inschrijfdatum'
      },
      {
        id: 'actions',
        label: 'Acties'
      }
    ] as { id: string, label: string }[],
    customers: [
      {
        id: 'firstName',
        label: 'Voornaam'
      },
      {
        id: 'lastName',
        label: 'Achternaam'
      },
      {
        id: 'email',
        label: 'E-mail'
      },
      {
        id: 'phoneNumber',
        label: 'Telefoon'
      },
      {
        id: 'bankDetails',
        label: 'IBAN'
      },
      {
        id: 'poniesSoldCount',
        label: '# Verkocht'
      },
      {
        id: 'poniesBoughtCount',
        label: '# Gekocht'
      },
      {
        id: 'costBenefitsTotal',
        label: '€ Balans'
      },
      {
        id: 'hasPaymentsPending',
        label: '(Uit)Betaald'
      },
      {
        id: 'auctionAgreementPDFUrl',
        label: 'Veilingovereenkomst'
      },
      {
        id: 'actions',
        label: 'Acties'
      }
    ] as { id: string, label: string }[]
  },
  defaultTableFilters: {
    customers: {
      hasSoldPonies: null,
      hasBoughtPonies: null,
    } as FiltersCustomersTable
  },

  auctionCostsPercentage: 6,
  specialKeys: [
    'Backspace',
    'Tab',
    'Enter',
    'Shift',
    'Control',
    'Alt',
    'CapsLock',
    'Escape',
    'PageUp',
    'PageDown',
    'End',
    'Home',
    'ArrowLeft',
    'ArrowUp',
    'ArrowRight',
    'ArrowDown',
    'PrintScreen',
    'Insert',
    'Delete',
  ],

  pageSizeOptions: [25, 50, 100, 250],

  // Default Options
  yesNoOptions: [
    {value: true, label: 'Ja'},
    {value: false, label: 'Nee'},
  ],
}
