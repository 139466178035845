import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {MockHttpClientPipe} from '../pipes/mock-data-fetch.pipe';
import {APP_LANGUAGES, I18nService} from './i18n.service';
import {cmsEntriesMockData} from '../../mock-data/data-sets/cms-entries';
import {CMSEntryType} from '../types/cms-entry.interface';

export type CMSRenderItem = { type: CMSEntryType, value: string };

export type CMSRenderSate = CMSRenderItem | null | undefined;

@Injectable({
  providedIn: 'root'
})
export class CmsEntriesService {
  private CMSRenderItems: {
    [key: string]: BehaviorSubject<CMSRenderSate>;
  } = {};

  constructor(
    private mockHttpClientPipe: MockHttpClientPipe,
    private i18nService: I18nService,
  ) {
  }

  getItem(key: string): Observable<CMSRenderSate> {
    if (this.CMSRenderItems[key]) {
      return this.CMSRenderItems[key].pipe();
    } else {
      return this.addKeyToList(key)
    }
  }

  private addKeyToList(key: string): Observable<CMSRenderSate> {
    // Add key
    this.CMSRenderItems[key] = new BehaviorSubject<CMSRenderSate>(undefined);

    // Async Call
    this.getFromApi(key);

    // Return Obs
    return this.CMSRenderItems[key].pipe();
  }

  private getFromApi(key: string): void {
    this.mockHttpClientPipe.transform<CMSRenderSate>({
      method: 'GET',
      comment: `Haalt "${key}" CMSEntry op, gebruik de userLanguage header om te kijken welke taal geserveerd moet worden, wanneer die taal niet beschikbaar is, stuur de NL content mee`,
      url: `public/cms_content/${key}`,
      mockReturnData: this.getMockResponse(key)
    }).subscribe(response => this.CMSRenderItems[key].next(response));
  }

  private getMockResponse(key: string): CMSRenderSate {
    const entry = cmsEntriesMockData?.find(entry => entry.id === key);
    const userLang = this.i18nService.getLanguageDirect();
    if (!userLang) return null;
    if (!entry) return null;
    return {
      type: entry.type,
      value: entry.values[userLang] || entry.values[APP_LANGUAGES[0]] || '',
    }
  }
}
