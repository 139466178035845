import {Component} from '@angular/core';
import {SharedModule} from '../../../shared/shared.module';
import {
  PublicFooterFriendsOfTheAuctionComponent
} from './public-footer-friends-of-the-auction/public-footer-friends-of-the-auction.component';
import {PublicFooterAdvertisersComponent} from './public-footer-advertisers/public-footer-advertisers.component';
import {APP_ROUTES} from '../../../app.navigation';
import {GetRenderedCmsEntryAsTextPipe} from '../../pipes/get-rendered-cms-entry-as-text.pipe';

@Component({
  selector: 'app-public-footer',
  standalone: true,
  imports: [SharedModule, PublicFooterFriendsOfTheAuctionComponent, PublicFooterAdvertisersComponent, GetRenderedCmsEntryAsTextPipe, GetRenderedCmsEntryAsTextPipe],
  templateUrl: './public-footer.component.html',
  styleUrls: ['./public-footer.component.scss']
})
export class PublicFooterComponent {
  protected readonly APP_ROUTES = APP_ROUTES;
}
