import {Pony, ponyGenderOptions, ponyPregnantOptions} from '../../../app/types/pony.interface';
import {createdMockCustomers} from '../customers';
import * as moment from 'moment';
import {marker} from '@biesbjerg/ngx-translate-extract-marker';
import {User} from '../../../app/types/user.interface';
import {usersMockData} from '../users';

const LOGGED_IN_USER_ID = 'LOGGED_IN_USER_ID';
const LOGGED_IN_USER_NAME = 'LOGGED_IN_USER_NAME';
const OTHER_USER_ID = 'OTHER_USER_ID';
const OTHER_USER_NAME = 'OTHER_USER_NAME';

const CUSTOMER_1_ID = 'CUSTOMER_1_ID';
const CUSTOMER_1_FIRST_NAME = 'CUSTOMER_1_FIRST_NAME';
const CUSTOMER_1_LAST_NAME = 'CUSTOMER_1_LAST_NAME';
const CUSTOMER_2_ID = 'CUSTOMER_2_ID';
const CUSTOMER_2_FIRST_NAME = 'CUSTOMER_2_FIRST_NAME';
const CUSTOMER_2_LAST_NAME = 'CUSTOMER_2_LAST_NAME';
const CUSTOMER_3_ID = 'CUSTOMER_3_ID';
const CUSTOMER_3_FIRST_NAME = 'CUSTOMER_3_FIRST_NAME';
const CUSTOMER_3_LAST_NAME = 'CUSTOMER_3_LAST_NAME';

export const createdMockPonies: Pony[] = [
  {
    id: '123',
    name: 'Beppie',
    isSelected: true,
    isConfirmed: false,
    pregnantType: ponyPregnantOptions[2],
    admittanceDate: moment().subtract(1, 'year').minute(0).second(0).millisecond(0).toDate(),
    chipNumber: '123-123-123',
    gender: ponyGenderOptions[1],
    dateOfBirth: moment().subtract(3, 'year').minute(0).second(0).millisecond(0).toDate(),
    sellerCustomerId: CUSTOMER_2_ID,
    sellerCustomerFirstName: CUSTOMER_2_FIRST_NAME,
    sellerCustomerLastName: CUSTOMER_2_LAST_NAME,
    fundsDisbursed: false,
    buyerCustomerId: undefined,
    buyerCustomerFirstName: undefined,
    buyerCustomerLastName: undefined,
    sellingPrice: undefined,
    purchaseAgreementPDFUrl: undefined,
    purchaseAgreementExcelUrl: undefined,
    conceptPurchaseAgreementPDFUrl: undefined,
    conceptPurchaseAgreementExcelUrl: undefined,
    fundsReceived: false,
    lockedByUserId: null,
    lockedByUserName: null,
    catalogueNumber: '001',
    facebookUrl: undefined,
    admittanceFeePaid: 'Nee',
    color: marker('ponyColors.Vos'),
    lifeNumber: '4123123',
    size: marker('ponySizes.Klein'),
    bioNL: 'Bio van dit mooie paardje, in het Nederlands. Bio van dit mooie paardje, in het Nederlands. Bio van dit mooie paardje, in het Nederlands. Bio van dit mooie paardje, in het Nederlands. Bio van dit mooie paardje, in het Nederlands.',
    photos: [
      {order: 1, path: 'https://images.spss.nu/mock-images/ponies/01.jpeg'},
      {order: 2, path: 'https://images.spss.nu/mock-images/ponies/02.jpeg'},
      {order: 3, path: 'https://images.spss.nu/mock-images/ponies/03.jpeg'}
    ],
    scanProofPath: 'https://images.spss.nu/mock-pdfs/ponies/drachtigheidsverklaring.pdf',
    scanProofFileName: 'drachtigheidsverklaring.pdf',
    familyTreePath: 'https://images.spss.nu/mock-images/family-trees/Beppie-stamboom-2024.jpg',
    familyTreeFileName: 'Beppie-stamboom-2024.jpg',
    isVisibleOnPublicPages: true,
    hasFoalPremium: true,
    hasYouthPremium: true,
    hasTwenterPremium: false,
    firstPremiumCount: 5,
    secondPremiumCount: 7,
    thirdPremiumCount: 0
  },

  {
    id: '789',
    isSelected: true,
    isConfirmed: true,
    pregnantType: ponyPregnantOptions[0],
    name: 'Sientje',
    admittanceDate: moment().subtract(1, 'year').subtract(4, 'months').minute(0).second(0).millisecond(0).toDate(),
    chipNumber: '789-789-789',
    gender: ponyGenderOptions[1],
    dateOfBirth: moment().subtract(1, 'year').subtract(4, 'months').minute(0).second(0).millisecond(0).toDate(),
    sellerCustomerId: CUSTOMER_2_ID,
    sellerCustomerFirstName: CUSTOMER_2_FIRST_NAME,
    sellerCustomerLastName: CUSTOMER_2_LAST_NAME,
    fundsDisbursed: true,
    buyerCustomerId: CUSTOMER_3_ID,
    buyerCustomerFirstName: CUSTOMER_3_FIRST_NAME,
    buyerCustomerLastName: CUSTOMER_3_LAST_NAME,
    sellingPrice: 111,
    purchaseAgreementPDFUrl: '/assets/pdf/purchase-agreement.pdf',
    purchaseAgreementExcelUrl: '/assets/excel/purchase-agreement.xlsx',
    conceptPurchaseAgreementPDFUrl: '/assets/pdf/concept-purchase-agreement.pdf',
    conceptPurchaseAgreementExcelUrl: '/assets/excel/concept-purchase-agreement.xlsx',
    fundsReceived: false,
    lockedByUserId: LOGGED_IN_USER_ID,
    lockedByUserName: LOGGED_IN_USER_NAME,
    catalogueNumber: '002',
    facebookUrl: 'https://www.facebook.com',
    admittanceFeePaid: 'Contant',
    color: marker('ponyColors.Paars'),
    lifeNumber: '123456789',
    size: undefined,
    bioNL: 'Bio in het Nederlands',
    photos: [{order: 1, path: 'https://images.spss.nu/mock-images/ponies/03.jpeg'}],
    isVisibleOnPublicPages: true,
  },
  {
    id: '456',
    name: 'Black Beauty',
    isSelected: true,
    isConfirmed: true,
    admittanceDate: moment().subtract(2, 'year').minute(0).second(0).millisecond(0).toDate(),
    chipNumber: '456-456-456',
    gender: ponyGenderOptions[1],
    pregnantType: ponyPregnantOptions[1],
    pregnantUntil: moment().subtract(2, 'weeks').add(2, 'days').minute(0).second(0).millisecond(0).toDate(),
    pregnantBy: 'Hengst Harrie',
    dateOfBirth: moment().subtract(4, 'year').minute(0).second(0).millisecond(0).toDate(),
    sellerCustomerId: CUSTOMER_1_ID,
    sellerCustomerFirstName: CUSTOMER_1_FIRST_NAME,
    sellerCustomerLastName: CUSTOMER_1_LAST_NAME,
    fundsDisbursed: false,
    fundsReceived: false,
    lockedByUserId: OTHER_USER_ID,
    lockedByUserName: OTHER_USER_NAME,
    catalogueNumber: '003',
    facebookUrl: 'https://www.facebook.com',
    admittanceFeePaid: 'Bank',
    color: marker('ponyColors.Palominobont'),
    lifeNumber: '22222222',
    size: marker('ponySizes.Groot'),
    bioEN: 'Bio in English',
    isVisibleOnPublicPages: true,
  },
  {
    id: '33323232',
    isSelected: true,
    isConfirmed: true,
    name: 'Japie',
    pregnantType: ponyPregnantOptions[2],
    admittanceDate: moment().subtract(1, 'year').subtract(4, 'weeks').minute(0).second(0).millisecond(0).toDate(),
    chipNumber: '777-666-444',
    gender: ponyGenderOptions[1],
    dateOfBirth: moment().subtract(2, 'year').subtract(4, 'days').minute(0).second(0).millisecond(0).toDate(),
    sellerCustomerId: CUSTOMER_3_ID,
    sellerCustomerFirstName: CUSTOMER_3_FIRST_NAME,
    sellerCustomerLastName: CUSTOMER_3_LAST_NAME,
    fundsDisbursed: false,
    buyerCustomerId: CUSTOMER_1_ID,
    buyerCustomerFirstName: CUSTOMER_1_FIRST_NAME,
    buyerCustomerLastName: CUSTOMER_1_LAST_NAME,
    sellingPrice: 222,
    purchaseAgreementPDFUrl: '/assets/pdf/purchase-agreement.pdf',
    purchaseAgreementExcelUrl: '/assets/excel/purchase-agreement.xlsx',
    fundsReceived: false,
    lockedByUserId: null,
    lockedByUserName: null,
    catalogueNumber: '004',
    facebookUrl: 'https://www.facebook.com',
    admittanceFeePaid: 'Contant',
    color: marker('ponyColors.Zwart'),
    lifeNumber: '33221',
    size: marker('ponySizes.Groot'),
    bioEN: 'Bio in English',
    photos: [{order: 1, path: 'https://images.spss.nu/mock-images/ponies/04.jpeg'}],
    isVisibleOnPublicPages: true,
  }
]

export function poniesMockData(loggedInUserId?: string): Pony[] {
  if (!loggedInUserId) return createdMockPonies || [];
  const loggedInUser: User | undefined = (usersMockData || []).find((user) => user.id === loggedInUserId);
  const otherUser: User | undefined = (usersMockData || []).find((user) => user.id !== loggedInUserId);
  if (!otherUser || !loggedInUser) return createdMockPonies || [];

  const customers = createdMockCustomers || [];
  const customerOne = customers[0];
  const customerTwo = customers[1];
  const customerThree = customers[2];

  return (createdMockPonies || []).map(pony => {
    if (pony.lockedByUserId === LOGGED_IN_USER_ID) pony.lockedByUserId = loggedInUser.id;
    if (pony.lockedByUserName === LOGGED_IN_USER_NAME) pony.lockedByUserId = loggedInUser.name;
    if (pony.lockedByUserId === OTHER_USER_ID) pony.lockedByUserId = otherUser.id;
    if (pony.lockedByUserName === OTHER_USER_ID) pony.lockedByUserId = otherUser.name;

    if (pony.sellerCustomerId === CUSTOMER_1_ID) pony.sellerCustomerId = customerOne.id;
    if (pony.sellerCustomerFirstName === CUSTOMER_1_FIRST_NAME) pony.sellerCustomerFirstName = customerOne.firstName;
    if (pony.sellerCustomerLastName === CUSTOMER_1_LAST_NAME) pony.sellerCustomerLastName = customerOne.lastName;
    if (pony.buyerCustomerId === CUSTOMER_1_ID) pony.buyerCustomerId = customerOne.id;
    if (pony.buyerCustomerFirstName === CUSTOMER_1_FIRST_NAME) pony.buyerCustomerFirstName = customerOne.firstName;
    if (pony.buyerCustomerLastName === CUSTOMER_1_LAST_NAME) pony.buyerCustomerLastName = customerOne.lastName;

    if (pony.sellerCustomerId === CUSTOMER_2_ID) pony.sellerCustomerId = customerTwo.id;
    if (pony.sellerCustomerFirstName === CUSTOMER_2_FIRST_NAME) pony.sellerCustomerFirstName = customerTwo.firstName;
    if (pony.sellerCustomerLastName === CUSTOMER_2_LAST_NAME) pony.sellerCustomerLastName = customerTwo.lastName;
    if (pony.buyerCustomerId === CUSTOMER_2_ID) pony.buyerCustomerId = customerTwo.id;
    if (pony.buyerCustomerFirstName === CUSTOMER_2_FIRST_NAME) pony.buyerCustomerFirstName = customerTwo.firstName;
    if (pony.buyerCustomerLastName === CUSTOMER_2_LAST_NAME) pony.buyerCustomerLastName = customerTwo.lastName;

    if (pony.sellerCustomerId === CUSTOMER_3_ID) pony.sellerCustomerId = customerThree.id;
    if (pony.sellerCustomerFirstName === CUSTOMER_3_FIRST_NAME) pony.sellerCustomerFirstName = customerThree.firstName;
    if (pony.sellerCustomerLastName === CUSTOMER_3_LAST_NAME) pony.sellerCustomerLastName = customerThree.lastName;
    if (pony.buyerCustomerId === CUSTOMER_3_ID) pony.buyerCustomerId = customerThree.id;
    if (pony.buyerCustomerFirstName === CUSTOMER_3_FIRST_NAME) pony.buyerCustomerFirstName = customerThree.firstName;
    if (pony.buyerCustomerLastName === CUSTOMER_3_LAST_NAME) pony.buyerCustomerLastName = customerThree.lastName;

    return pony;
  });
}
