import {Edition} from '../../app/types/edition.interface';
import * as moment from 'moment';

export const editionsMockData: Edition[] = [
  {
    id: '123',
    deadlineDate: moment().subtract(30, 'd').toDate(),
    auctionDate: moment().toDate(),
    edition: 14
  }
];
