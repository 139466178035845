import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {MockHttpClientPipe} from '../pipes/mock-data-fetch.pipe';
import {getMockData} from '../../mock-data/mock-data';

export type StaticData = {
  foals: number;
  ponyIsFoalWhenBornAfterDate: Date;
  adolescents: number;
  adults: number;
  ponyIsAdultWhenBornBeforeDate: Date;
}

@Injectable({
  providedIn: 'root'
})
export class StaticDataService {
  private staticData$ = new BehaviorSubject<StaticData | null>(null);

  constructor(
    private mockHttpClientPipe: MockHttpClientPipe,
  ) {
  }

  getStaticDataFromApi() {
    this.mockHttpClientPipe.transform<StaticData>({
      method: 'GET',
      url: `public/static_data`,
      comment: 'Static data ophalen',
      mockReturnData: getMockData().staticData!
    }).subscribe(staticData => {
      if (staticData) {
        this.staticData$.next(staticData);
      }
    });
  }

  getStaticData() {
    return this.staticData$;
  }
}
