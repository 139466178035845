import {FriendOfTheAuction} from '../../app/types/friend-of-the-auction.interface';

export const friendsOfTheAuctionMockData: FriendOfTheAuction[] = [
  {
    id: '123',
    city: 'Hoevelaken',
    email: '123@123.nl',
    phoneNumber: '0612345678',
    website: 'https://stalvanderlaack.nl',
    fullName: 'Paola en Peter Collignon',
    isApproved: true,
  },
  {
    id: '1231',
    companyName: 'Stal v.d. Leyenhof',
    email: '123@123.nl',
    phoneNumber: '0612345678',
    city: 'Kerkdriel',
    fullName: 'Henk de Grouw',
    isApproved: true,
  },
  {
    id: '112312223',
    email: '123@123.nl',
    phoneNumber: '0612345678',
    city: 'Bavel',
    website: 'www.shetlandstaldetoekomst.nl',
    fullName: 'Ruud van Raak',
    isApproved: true,
  },
  {
    id: 'aa123',
    city: 'Sint-Michielsgestel',
    email: '123@123.nl',
    phoneNumber: '0612345678',
    website: 'www.staldehorzik.nl',
    fullName: 'Hein en Helma van Roij',
    isApproved: true,
  },
  {
    id: '0612345678sa123',
    companyName: 'Timmerwerk op maat',
    email: '123@123.nl',
    phoneNumber: '0612345678',
    city: 'Dussen',
    website: 'www.chrisvanandel.nl',
    fullName: 'Chris van Andel',
    isApproved: true,
  },
  {
    id: '123',
    city: 'De Rips',
    email: '123@123.nl',
    phoneNumber: '0612345678',
    fullName: 'Jo van de Berg',
    isApproved: true,
  },
  {
    id: '12hhhh3',
    companyName: 'Stal de Buckshoeve',
    email: '123@123.nl',
    phoneNumber: '0612345678',
    city: 'Den Bosch',
    fullName: 'Hans van den Broek',
    isApproved: true,
  },
  {
    id: '123hjj',
    city: 'Overasselt',
    website: 'www.stalsportview.nl',
    fullName: 'Martin Broens',
    email: '123@123.nl',
    phoneNumber: '0612345678',
    isApproved: true,
  },
  {
    id: '123hhhhhh',
    companyName: 'Stal van \'t Terp',
    city: 'Brakel',
    fullName: 'Gijs Dijkhof',
    email: '123@123.nl',
    phoneNumber: '0612345678',
    isApproved: true,
  },
  {
    id: '123jjjjjj',
    city: 'Chieming (BRD)',
    website: 'www.elmhorst-checkenponies.de',
    fullName: 'Stephan Elmer',
    isApproved: true,
    email: '123@123.nl',
    phoneNumber: '0612345678',
  },
  {
    id: '123bbbbbbb',
    companyName: 'Stal van \'t Schut',
    city: 'Lunteren',
    fullName: 'Willem van Harten',
    isApproved: true,
    email: '123@123.nl',
    phoneNumber: '0612345678',
  },
  {
    id: '123hhhh',
    companyName: 'Stal van \'t Sterrebos',
    city: 'Nederhemert',
    fullName: 'Peter Keijnemans',
    isApproved: true,
    email: '123@123.nl',
    phoneNumber: '0612345678',
  },
  {
    id: '123555555',
    companyName: 'Stal Wordragen',
    website: 'Ammerzoden',
    fullName: 'Fam. van Loon',
    isApproved: true,
    email: '123@123.nl',
    phoneNumber: '0612345678',
  }
];
